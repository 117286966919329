import type { TUser } from "@trooper/zod";
import { type PropsWithChildren, createContext, useContext } from "react";

interface UserContextProps extends PropsWithChildren {
  user?: TUser | null;
}
export const UserContext = createContext<UserContextProps>({ user: undefined });

export default function AddressProvider({ user, children }: UserContextProps) {
  return (
    <UserContext.Provider value={{ user }}>{children}</UserContext.Provider>
  );
}

export const useUser = () => {
  const context = useContext(UserContext);
  if (context === undefined) {
    throw new Error("`useUser` must be within a `UserContext`");
  }
  return context;
};
